<template>
	<div>
		<!-- Sidebar backdrop (mobile only) -->
		<div class="fixed inset-0 bg-gray-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200" :class="sidebarOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'" aria-hidden="true"></div>

		<!-- Sidebar -->
		<div id="sidebar" ref="sidebar" class="flex flex-col absolute z-40 left-0 top-0 lg:relative lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-gray-800 p-4 transition-all duration-200 ease-in-out" :class="{ 'translate-x-0': sidebarOpen, '-translate-x-64 ': !sidebarOpen, 'hover:sidebar-expanded': !sidebarExpanded && expandOnHover }">
			<!-- Sidebar header -->
			<div class="flex justify-between mb-4 pr-3 sm:px-2">
				<!-- Close button -->
				<button ref="trigger" class="lg:hidden p-2 text-white opacity-80 hover:opacity-100" @click.stop="$emit('close-sidebar')" aria-controls="sidebar" :aria-expanded="sidebarOpen">
					<span class="sr-only">Close sidebar</span>
					<icon :name="`ri-arrow-${!sidebarExpanded ? 'right' : 'left'}-line`"></icon>
				</button>
				<!-- Logo -->
				<router-link class="block" to="/">
					<svg width="32" height="32" viewBox="0 0 32 32">
						<rect class="fill-primary-400" width="32" height="32" rx="16" />
						<path d="M18.277.16C26.035 1.267 32 7.938 32 16c0 8.837-7.163 16-16 16a15.937 15.937 0 01-10.426-3.863L18.277.161z" class="fill-primary-600" />
						<path d="M7.404 2.503l18.339 26.19A15.93 15.93 0 0116 32C7.163 32 0 24.837 0 16 0 10.327 2.952 5.344 7.404 2.503z" class="fill-primary-700" />
						<path d="M2.223 24.14L29.777 7.86A15.926 15.926 0 0132 16c0 8.837-7.163 16-16 16-5.864 0-10.991-3.154-13.777-7.86z" class="fill-primary-500" />
					</svg>
				</router-link>
			</div>

			<!-- Links -->
			<div class="space-y-8 overflow-y-auto py-5" @mouseenter="() => (expandOnHover = !sidebarExpanded && !expandOnHover && !sidebarExpanded)" @mouseleave="() => (expandOnHover = !sidebarExpanded && expandOnHover && !expandOnHover)">
				<!-- Pages group -->
				<div>
					<h3 class="text-xs uppercase text-gray-500 font-semibold pl-3">
						<span class="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6" aria-hidden="true">•••</span>
						<span class="lg:hidden lg:sidebar-expanded:block 2xl:block">Menú</span>
					</h3>

					<ul class="mt-3">
						<!-- Dashboard -->
						<router-link :to="{ name: 'go.map' }" custom v-slot="{ href, navigate, isExactActive }">
							<li class="mb-1 last:mb-0 relative">
								<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(isExactActive && 'bg-primary-600') || (!isExactActive && 'hover:bg-primary-500 hover:bg-opacity-30')" :href="href" @click="navigate">
									<div class="flex items-center">
										<icon name="ri-compass-3-line" class="opacity-70 w-6 h-6" />
										<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Mapa</span>
									</div>
								</a>
							</li>
						</router-link>
						<router-link :to="{ name: 'go.deals' }" custom v-slot="{ href, navigate, isExactActive }">
							<li class="mb-1 last:mb-0 relative">
								<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(isExactActive && 'bg-primary-600') || (!isExactActive && 'hover:bg-primary-500 hover:bg-opacity-30')" :href="href" @click="navigate">
									<div class="flex items-center">
										<icon name="ri-briefcase-line" class="opacity-70 w-6 h-6" />
										<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Cartera</span>
									</div>
								</a>
							</li>
						</router-link>
						<router-link v-if="user.permissions?.includes('cups')" :to="{ name: 'go.cupsGet' }" custom v-slot="{ href, navigate, isExactActive }">
							<li class="mb-1 last:mb-0 relative">
								<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(isExactActive && 'bg-primary-600') || (!isExactActive && 'hover:bg-primary-500 hover:bg-opacity-30')" :href="href" @click="navigate">
									<div class="flex items-center">
										<icon name="ri-search-line" class="opacity-70 w-6 h-6" />
										<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Consultar Cups</span>
									</div>
								</a>
							</li>
						</router-link>
						<router-link v-if="user.usersCount > 1" :to="{ name: 'go.opportunities' }" custom v-slot="{ href, navigate, isExactActive }">
							<li class="mb-1 last:mb-0 relative">
								<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(isExactActive && 'bg-primary-600') || (!isExactActive && 'hover:bg-primary-500 hover:bg-opacity-30')" :href="href" @click="navigate">
									<div class="flex items-center">
										<icon name="ri-hand-coin-line" class="opacity-70 w-6 h-6" />
										<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Oportunidades</span>
									</div>
								</a>
							</li>
						</router-link>
						<router-link v-if="user.permissions?.includes('user.find')" :to="{ name: 'go.users' }" custom v-slot="{ href, navigate, isExactActive }">
							<li class="mb-1 last:mb-0 relative">
								<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(isExactActive && 'bg-primary-600') || (!isExactActive && 'hover:bg-primary-500 hover:bg-opacity-30')" :href="href" @click="navigate">
									<div class="flex items-center">
										<icon name="ri-group-line" class="opacity-70 w-6 h-6" />
										<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Usuarios</span>
									</div>
								</a>
							</li>
						</router-link>
						<router-link v-if="user.permissions?.includes('user.group.find')" :to="{ name: 'go.userGroupList' }" custom v-slot="{ href, navigate, isExactActive }">
							<li class="mb-1 last:mb-0 relative">
								<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(isExactActive && 'bg-primary-600') || (!isExactActive && 'hover:bg-primary-500 hover:bg-opacity-30')" :href="href" @click="navigate">
									<div class="flex items-center">
										<icon name="ri-user-settings-line" class="opacity-70 w-6 h-6" />
										<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Grupos de Usuarios</span>
									</div>
								</a>
							</li>
						</router-link>
						<router-link v-if="user.permissions?.includes('role.find')" :to="{ name: 'go.roleList' }" custom v-slot="{ href, navigate, isExactActive }">
							<li class="mb-1 last:mb-0 relative">
								<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(isExactActive && 'bg-primary-600') || (!isExactActive && 'hover:bg-primary-500 hover:bg-opacity-30')" :href="href" @click="navigate">
									<div class="flex items-center">
										<icon name="ri-shield-user-line" class="opacity-70 w-6 h-6" />
										<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Roles de usuarios</span>
									</div>
								</a>
							</li>
						</router-link>
						<router-link v-if="user.permissions?.includes('lead.file.import') || user.permissions?.includes('lead.file.update')" :to="{ name: 'go.leadImport' }" custom v-slot="{ href, navigate, isExactActive }">
							<li class="mb-1 last:mb-0 relative">
								<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(isExactActive && 'bg-primary-600') || (!isExactActive && 'hover:bg-primary-500 hover:bg-opacity-30')" :href="href" @click="navigate">
									<div class="flex items-center">
										<icon name="ri-file-upload-line" class="opacity-70 w-6 h-6" />
										<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Cargar Leads</span>
									</div>
								</a>
							</li>
						</router-link>
						<router-link v-if="user.permissions?.includes('user.file.import') || user.permissions?.includes('user.file.update')" :to="{ name: 'go.userImport' }" custom v-slot="{ href, navigate, isExactActive }">
							<li class="mb-1 last:mb-0 relative">
								<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(isExactActive && 'bg-primary-600') || (!isExactActive && 'hover:bg-primary-500 hover:bg-opacity-30')" :href="href" @click="navigate">
									<div class="flex items-center">
										<icon name="ri-contacts-book-upload-line" class="opacity-70 w-6 h-6" />
										<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Cargar Usuarios</span>
									</div>
								</a>
							</li>
						</router-link>
						<router-link v-if="user.permissions?.includes('lead.file.geolocation')" :to="{ name: 'go.leadGeolocalize' }" custom v-slot="{ href, navigate, isExactActive }">
							<li class="mb-1 last:mb-0 relative">
								<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(isExactActive && 'bg-primary-600') || (!isExactActive && 'hover:bg-primary-500 hover:bg-opacity-30')" :href="href" @click="navigate">
									<div class="flex items-center">
										<icon name="ri-global-line" class="opacity-70 w-6 h-6"></icon>
										<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Geolocalizar Leads</span>
									</div>
								</a>
							</li>
						</router-link>
					</ul>
				</div>
				<div v-if="user.role === 'root' || user.role === 'administrator'">
					<h3 class="text-xs uppercase text-gray-500 font-semibold pl-3">
						<span class="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6" aria-hidden="true">•••</span>
						<span class="lg:hidden lg:sidebar-expanded:block 2xl:block">Admin</span>
					</h3>
					<ul class="mt-3">
						<router-link v-if="user.permissions?.includes('dealAction.find')" :to="{ name: 'go.dealActions' }" custom v-slot="{ href, navigate, isExactActive }">
							<li class="mb-1 last:mb-0 relative">
								<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(isExactActive && 'bg-primary-600') || (!isExactActive && 'hover:bg-primary-500 hover:bg-opacity-30')" :href="href" @click="navigate">
									<div class="flex items-center">
										<icon name="ri-file-settings-line" class="opacity-70 w-6 h-6" />
										<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Acciones</span>
									</div>
								</a>
							</li>
						</router-link>
						<router-link v-if="user.permissions?.includes('permission.find.private')" :to="{ name: 'go.permissionList' }" custom v-slot="{ href, navigate, isExactActive }">
							<li class="mb-1 last:mb-0 relative">
								<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(isExactActive && 'bg-primary-600') || (!isExactActive && 'hover:bg-primary-500 hover:bg-opacity-30')" :href="href" @click="navigate">
									<div class="flex items-center">
										<icon name="ri-shield-line" class="opacity-70 w-6 h-6" />
										<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Permisos</span>
									</div>
								</a>
							</li>
						</router-link>
						<router-link v-if="user.permissions?.includes('project.admin.find')" :to="{ name: 'go.projectList' }" custom v-slot="{ href, navigate, isExactActive }">
							<li class="mb-1 last:mb-0 relative">
								<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(isExactActive && 'bg-primary-600') || (!isExactActive && 'hover:bg-primary-500 hover:bg-opacity-30')" :href="href" @click="navigate">
									<div class="flex items-center">
										<icon name="ri-lightbulb-flash-line" class="opacity-70 w-6 h-6" />
										<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Proyectos</span>
									</div>
								</a>
							</li>
						</router-link>
						<SidebarLinkGroup :activeCondition="currentRoute.fullPath.startsWith('/routes/')" title="Rutas" iconName="ri-route-line" v-if="user.permissions?.some(permission => ['system.routes'].includes(permission))">
							<router-link v-if="user.permissions?.some(permission => ['system.routes'].includes(permission))" :to="{ name: 'go.routesList' }" custom v-slot="{ href, navigate, isExactActive }">
								<li class="mb-1 last:mb-0 relative">
									<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(isExactActive && 'bg-primary-600') || (!isExactActive && 'hover:bg-primary-500 hover:bg-opacity-30')" :href="href" @click="navigate">
										<div class="flex items-center">
											<icon name="ri-guide-line" class="opacity-70 w-6 h-6" />
											<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Rutas Front</span>
										</div>
									</a>
								</li>
							</router-link>
							<router-link v-if="user.permissions?.some(permission => ['system.routes'].includes(permission))" :to="{ name: 'go.routesApiList' }" custom v-slot="{ href, navigate, isExactActive }">
								<li class="mb-1 last:mb-0 relative">
									<a class="block text-gray-200 hover:text-white truncate transition duration-150 px-3 py-2 rounded-sm" :class="(isExactActive && 'bg-primary-600') || (!isExactActive && 'hover:bg-primary-500 hover:bg-opacity-30')" :href="href" @click="navigate">
										<div class="flex items-center">
											<icon name="ri-guide-line" class="opacity-70 w-6 h-6" />
											<span class="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Rutas API</span>
										</div>
									</a>
								</li>
							</router-link>
						</SidebarLinkGroup>
					</ul>
				</div>
			</div>

			<!-- Expand / collapse button -->
			<div class="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
				<button class="p-2 text-white opacity-80 hover:opacity-100" @click.prevent="sidebarExpanded = !sidebarExpanded">
					<icon :name="`ri-arrow-${!sidebarExpanded ? 'right' : 'left'}-line`" class="w-6 h-6"></icon>
				</button>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { user } from '@/user'
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import SidebarLinkGroup from './SidebarLinkGroup.vue'

const props = defineProps(['sidebarOpen'])
const emit = defineEmits(['close-sidebar'])
const { currentRoute } = useRouter()

const trigger = ref(null)
const sidebar = ref(null)

const storedSidebarExpanded = localStorage.getItem('sidebar-expanded')
const sidebarExpanded = ref(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true')
const expandOnHover = ref(false)

// close on click outside
const clickHandler = ({ target }) => {
	if (!sidebar.value || !trigger.value) return
	if (!props.sidebarOpen || sidebar.value?.contains(target) || trigger.value?.contains(target)) return
	emit('close-sidebar')
}

// close if the esc key is pressed
const keyHandler = ({ keyCode }) => {
	if (!props.sidebarOpen || keyCode !== 27) return
	emit('close-sidebar')
}

onMounted(() => {
	document.addEventListener('click', clickHandler)
	document.addEventListener('keydown', keyHandler)
})

onUnmounted(() => {
	document.removeEventListener('click', clickHandler)
	document.removeEventListener('keydown', keyHandler)
})

watch([sidebarExpanded, expandOnHover], () => {
	localStorage.setItem('sidebar-expanded', sidebarExpanded.value.toString())
	if (sidebarExpanded.value || expandOnHover.value) {
		document.querySelector('body').classList.add('sidebar-expanded')
	} else {
		document.querySelector('body').classList.remove('sidebar-expanded')
	}
})
</script>
