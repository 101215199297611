import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'

const routes: RouteRecordRaw[] = [
	{
		name: 'auth.login',
		path: '/auth/login',
		component: null
	},
	{
		name: 'auth.set-password',
		path: '/auth/set-password',
		component: null
	},
	{
		name: 'auth.recover',
		path: '/auth/recover',
		component: null
	},
	{
		name: 'auth.reset',
		path: '/auth/reset',
		component: null
	},
	{
		name: 'lead.import',
		path: '/lead/import',
		component: null
	},
	{
		name: 'group',
		path: '/user/group/:_id?',
		component: null
	},
	{
		name: 'user.activate',
		path: '/user/:_id/activate',
		component: null
	},
	{
		name: 'user.deactivate',
		path: '/user/:_id/deactivate',
		component: null
	},
	{
		name: 'user.invite',
		path: '/user/invite',
		component: null
	},
	{
		name: 'user.count',
		path: '/user/count',
		component: null
	},
	{
		name: 'user.file.import',
		path: '/user/file/import',
		component: null
	},
	{
		name: 'user.download',
		path: '/user/download',
		component: null
	},
	{
		name: 'me.password',
		path: '/me/password',
		component: null
	},
	{
		name: 'user',
		path: '/user/:_id?',
		component: null
	},
	{
		name: 'user.billing',
		path: '/user/:_id/bill',
		component: null
	},
	{
		name: 'deal.download',
		path: '/:project/deal/download',
		component: null
	},
	{
		name: 'deal.change.status',
		path: '/:project/deal/:_id/change/status',
		component: null
	},
	{
		name: 'deal.customer',
		path: '/:project/deal/:_id/customer',
		component: null
	},
	{
		name: 'deal.loc',
		path: '/:project/deal/:_id/loc',
		component: null
	},
	{
		name: 'deal.contract',
		path: '/:project/deal/:_id/contract',
		component: null
	},
	{
		name: 'deal.preview',
		path: '/:project/deal/:_id/preview',
		component: null
	},
	{
		name: 'deal.migrate',
		path: '/:project/deal/migrate',
		component: null
	},
	{
		name: 'deal.count',
		path: '/:project/deal/count',
		component: null
	},
	{
		name: 'deal',
		path: '/:project/deal/:_id?',
		component: null
	},
	{
		name: 'action',
		path: '/action/:_id?',
		component: null
	},
	{
		name: 'action.form',
		path: '/action/:action/form',
		component: null
	},
	{
		name: 'deal.action',
		path: '/:project/deal/:_id/action',
		component: null
	},
	{
		name: 'deal.action.bulk',
		path: '/:project/deal/action/bulk',
		component: null
	},
	{
		name: 'me.deal',
		path: '/:project/me/deal',
		component: null
	},
	{
		name: 'deal.customer.comment',
		path: '/:project/deal/:_id/customer/comment/:commentId?',
		component: null
	},
	{
		name: 'deal.customer.email',
		path: '/:project/deal/:_id/customer/email/:emailId?',
		component: null
	},
	{
		name: 'deal.customer.phone',
		path: '/:project/deal/:_id/customer/phone/:phoneId?',
		component: null
	},
	{
		name: 'deal.contract.comment',
		path: '/:project/deal/:_id/contract/comment/:commentId?',
		component: null
	},
	{
		name: 'lead.geolocation',
		path: '/:project/lead/geolocation',
		component: null
	},
	{
		name: 'lead.migrate',
		path: '/:project/lead/migrate',
		component: null
	},
	{
		name: 'lead',
		path: '/:project/lead',
		component: null
	},
	{
		name: 'lead.preview',
		path: '/:project/lead/:_id/preview',
		component: null
	},
	{
		name: 'lead.temp.lock',
		path: '/:project/lead/:_id/temp/lock',
		component: null
	},
	{
		name: 'lead.loc',
		path: '/:project/lead/:_id/loc',
		component: null
	},
	{
		name: 'lead.lock',
		path: '/:project/lead/:_id/lock',
		component: null
	},
	{
		name: 'lead.assign',
		path: '/:project/lead/:_id/assign/:userId',
		component: null
	},
	{
		name: 'lead.generate.template',
		path: '/lead/file/generate/template',
		component: null
	},
	{
		name: 'lead.file.import',
		path: '/:project/lead/file/import',
		component: null
	},
	{
		name: 'lead.file.update',
		path: '/:project/lead/file/update',
		component: null
	},
	{
		name: 'user.generate.template',
		path: '/user/file/generate/template',
		component: null
	},
	{
		name: 'user.file.import',
		path: '/user/file/import',
		component: null
	},
	{
		name: 'user.file.update',
		path: '/user/file/update',
		component: null
	},
	{
		name: 'lead.file.geolocation',
		path: '/lead/file/geolocation',
		component: null
	},
	{
		name: 'system.routes',
		path: '/system/routes',
		component: null
	},
	{
		name: 'project',
		path: '/project/:_id?',
		component: null
	},
	{
		name: 'contract',
		path: '/contract/nemon/cups/:cups',
		component: null
	},
	{
		name: 'role',
		path: '/role/:_id?',
		component: null
	},
	{
		name: 'organization',
		path: '/organization',
		component: null
	},
	{
		name: 'permission',
		path: '/permission/:_id?',
		component: null
	},
	{
		name: 'category',
		path: '/category/:_id?',
		component: null
	},
	{
		name: 'territory',
		path: '/territory',
		component: null
	},
	{
		name: 'network-type',
		path: '/network-type',
		component: null
	},
	{
		name: 'origin',
		path: '/origin',
		component: null
	}
]

const routerHistory = createWebHistory()
const apiRouter = createRouter({
	history: routerHistory,
	routes
})

export default apiRouter
