<template>
	<Loading v-show="loading" />
	<div class="relative flex h-full w-full p-3">
		<div v-if="lead" class="grow flex flex-col md:translate-x-0 transform transition-transform duration-300 ease-in-out translate-x-0">
			<div class="bg-white rounded-sm border border-gray-200 mb-2">
				<div class="text-xs text-gray-800">
					<Banner :open="true" type="warning" class="bg-opacity-30" fixed v-if="lead.tempLockExpiresAt">
						<p><b>Reservado temporalmente: </b> si decides no bloquearlo, {{ dayjs(lead.tempLockExpiresAt).fromNow() }} este lead estará disponible para que otro comercial pueda gestionarlo.</p>
					</Banner>
				</div>
			</div>
			<div class="sticky top-14 bg-white z-10 mb-2">
				<div class="flex items-center justify-end border-b border-gray-200 px-2 h-14">
					<Button v-if="!lead.locked" @click="lock()" :loading="locking" loadingLabel="Bloqueando..." :disabled="locking || lead.locked">
						<icon name="ri-lock-line" class="opacity-70 w-5 h-5 mr-1" />
						Bloquear
					</Button>
				</div>
			</div>
			<ContractSummaryEnergy :distance="props.distance" v-if="!isFinance" :contract="lead.contract" :deactivateAt="lead.deactivateAt" class="mb-2" />
			<ContractSummaryFinance :distance="props.distance" v-if="isFinance" :contract="lead.contract" class="mb-2" />
			<LeadAddress :lead="lead" :project="props.project" class="mb-2" />
			<Comparative v-if="!isFinance" :contract="lead.contract" />
		</div>
	</div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, watch } from 'vue'
import Button from '../../components/Button.vue'
import Banner from '../../components/Banner.vue'
import Loading from '../../components/Loading.vue'
import { Lead } from '../../interfaces/Lead'
import { get, put, del, post } from '../../services/api'
import { dayjs } from '@/services/dayjs'
import LeadAddress from '@/apps/go/components/leads/LeadAddress.vue'
import ContractSummaryEnergy from '../../components/leads/ContractSummaryEnergy.vue'
import ContractSummaryFinance from '../../components/leads/ContractSummaryFinance.vue'
import Comparative from '../../components/leads/Comparative.vue'
const isFinance = import.meta.env.VITE_PRODUCT == 'finance'
const props = defineProps<{ _id: string; lockOnFetch: boolean; project: string; distance: number }>()
const lead = ref<Lead | null>(null)
const loading = ref(false)
const locking = ref(false)
const emit = defineEmits(['lock'])

const fetch = async () => {
	loading.value = true

	try {
		if (props.lockOnFetch) {
			const { data } = await post({ name: 'lead.temp.lock', params: { project: props.project, _id: props._id } }, {})
			lead.value = data
		} else {
			const { data } = await get({ name: 'lead.preview', params: { project: props.project, _id: props._id } })
			lead.value = data
		}
	} catch (error) {
		let message = props.lockOnFetch ? 'Este lead ya está bloqueado por otro comercial' : 'No se ha podido cargar el lead'
		alert(message)
	}

	loading.value = false
}

const lock = async () => {
	try {
		loading.value = true
		locking.value = true
		const { data } = await put({ name: 'lead.lock', params: { project: props.project, _id: props._id } })
		loading.value = false
		locking.value = false
		emit('lock', data)
	} catch (error) {
		alert('A ocurrido un error')
	}
}

const unlock = async () => {
	try {
		if (lead.value && props.lockOnFetch) {
			del({ name: 'lead.temp.lock', params: { project: props.project, _id: lead.value._id } })
		}
	} catch (error) {
		alert('A ocurrido un error')
	}
}

onUnmounted(async () => {
	await unlock()
	lead.value = null
})

onMounted(() => {
	fetch()
})

watch(
	() => props._id,
	_id => {
		unlock()
		if (props._id) {
			fetch()
		}
	}
)
</script>
